Events = require("src/events")

$ ->
  dictionaryButton = $("[data-toggle=dictionary]")
  return if dictionaryButton.length is 0 || dictionaryButton.hasClass("disabled")

  dictionaryButton.click (e) ->
    Events.trigger("dictionary:toggle")

  $(".question").getWordByEvent "dblclick", (e, word) ->
    target = $(e.target)
    return if target.is("input, img, video, audio, label, choice")
    return if target.hasClass("no-dictionary")
    return if target.closest(".no-dictionary").length > 0
    e.preventDefault()
    word = String(word).replace(/^[\s.:,;?!]+|[\s.:,;?!]+$/g, '');
    if word isnt ""
      toggleWizardPopup("dictionary") if $(".wizard-popup.dictionary").hasClass("hidden")
      Events.trigger("dictionary:show", word)
    else
      toggleWizardPopup("dictionary") unless $(".wizard-popup.dictionary").hasClass("hidden")
