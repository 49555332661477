import React from "react"
import classNames from "classnames"
import {div, input, label} from "react-dom-factories"
import Wrapper from "./wrapper"
import _ from "lodash"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @id = _.uniqueId("input-")
    @state =
      changed: false

  onChange: (event) =>
    @setState(changed: true)
    @props.onChange(event.target.checked)

  render: ->
    React.createElement Wrapper,
      Object.assign({}, @props, htmlFor: @id, changed: @state.changed)
      div
        className: "checkbox"
        label
          htmlFor: @id
          input
            id: @id
            type: "checkbox"
            checked: @props.value
            onChange: @onChange
            disabled: @props.disabled
          @props.children

export default Component
