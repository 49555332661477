React = require("react")
VirtualSelect = require("react-virtualized-select").default
classNames = require("classnames")

{div, label, select, option, input, h1, h2, a, ul, li, form, br, table, thead, tbody, tr, th, td} = require("react-dom-factories")

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      institution: props.institution || ""
      learningModule: props.learningModule || ""
      availableLearningModules: props.availableLearningModules || []
      items: []
      importStarted: false

  onInstitutionSelect: (value) =>
    @setState
      institution: value.label
      learningModule: ""
      availableLearningModules: @props.availableInstitutions.find((i) -> i.name is value.label).learningModules

  onLearningModuleSelect: (value) =>
    @setState(learningModule: value.label)

  onDragOver: (event) =>
    event.preventDefault()

  onDrop: (event) =>
    event.preventDefault()
    return if @state.importStarted
    items = [].concat(@state.items)
    for file in event.dataTransfer.files
      items.push
        file: file
    @setState(items: items)

  onChooseItems: (event) =>
    return if @state.importStarted
    @refs.fileInput.click()

  onFileInputChange: (event) =>
    items = [].concat(@state.items)
    for file in @refs.fileInput.files
      items.push
        file: file
    @setState(items: items)
    $(@refs.fileInput).val("")

  onRemoveItem: (index, event) =>
    event.preventDefault()
    event.stopPropagation()
    items = [].concat(@state.items)
    items.splice(index, 1)
    @setState(items: items)

  onSubmit: (event) =>
    event.preventDefault()
    return unless @isSubmittable()
    @setState(importStarted: true)
    @performImport(item) for item in @state.items

  performImport: (item) =>
    item.state = "pending"

    data = new FormData()
    data.append("authenticity_token", $("meta[name='csrf-token']").attr("content"))
    data.append("form[institution]", @state.institution)
    data.append("form[learning_module]", @state.learningModule)
    data.append("form[file]", item.file)

    req = new XMLHttpRequest()
    req.open("PUT", "/#{@props.role}/exercises/import")
    req.withCredentials = true
    req.onreadystatechange = =>
      return unless req.readyState is 4

      unless req.status is 200
        item.state = "error"
        item.error = I18n.screw("HTTP-Code %{code}", code: req.status)
        @forceUpdate()
        return

      data = JSON.parse(req.responseText)
      switch data.status
        when "ok"
          item.state = "imported"
          item.exerciseId = data.exercise_id
        when "error"
          item.state = "error"
          item.error = I18n.screw("Datei ungültig")
      @forceUpdate()
    req.send(data)

  isSubmittable: =>
    @state.learningModule isnt "" && @state.items.length > 0

  render: =>
    form null,
      if @props.availableInstitutions
        div null,
          div
            className: "row"
            div
              className: "col-xs-16"
              div
                className: "form-group"
                label
                  className: "col-sm-2 control-label"
                  I18n.screw("Institution")
                div
                  className: "col-sm-10"
                  React.createElement VirtualSelect,
                    value:
                      value: @state.institution
                      label: @state.institution
                    clearable: false
                    backspaceRemoves: false
                    onChange: @onInstitutionSelect
                    options: @props.availableInstitutions.map((i) -> {value: i.name, label: i.name})
                    disabled: @state.importStarted
          br null

      div
        className: "row"
        div
          className: "col-xs-16"
          div
            className: "form-group"
            label
              className: "col-sm-2 control-label"
              I18n.screw("Lernmodul")
            div
              className: "col-sm-10"
              React.createElement VirtualSelect,
                value:
                  value: @state.learningModule
                  label: @state.learningModule
                clearable: false
                backspaceRemoves: false
                onChange: @onLearningModuleSelect
                options: @state.availableLearningModules.map((name) -> {value: name, label: name})
                disabled: @state.importStarted
      br null

      div
        className: "row"
        div
          className: "col-xs-16"
          div
            className: "form-group"
            label
              className: "col-sm-2 control-label"
              I18n.screw("Übungen")
            div
              className: "col-sm-10"
              div
                className: "well"
                onDrop: @onDrop
                onDragOver: @onDragOver
                onClick: @onChooseItems
                if @state.items.length > 0
                  table
                    className: "table table-striped"
                    thead null,
                      tr null,
                        th null,
                          I18n.screw("Dateiname")
                        th null,
                          I18n.screw("Größe")
                        th null,
                          I18n.screw("Status")
                    tbody null,
                      for item, i in @state.items
                        tr
                          key: i
                          td null,
                            item.file.name
                          td null,
                            I18n.toHumanSize(item.file.size)
                          td null,
                            switch item.state
                              when "pending"
                                I18n.screw("Import läuft...")
                              when "imported"
                                div null,
                                  I18n.screw("Import erfolgreich")
                                  " ("
                                  a
                                    href: "/#{@props.role}/exercises/#{item.exerciseId}"
                                    target: "_blank"
                                    I18n.screw("Übung ansehen")
                                  ")"
                              when "error"
                                I18n.screw("Fehler: %{error}", error: item.error)
                              else
                                a
                                  className: "pull-right"
                                  onClick: @onRemoveItem.bind(@, i)
                                  I18n.screw("entfernen")
                else
                  div null,
                    I18n.screw("Legen Sie hier die zu importierenden Revoca5-Dateien per Drag'n Drop ab.")
              input
                ref: "fileInput"
                type: "file"
                accept: ".rvo5"
                className: "hide"
                multiple: true
                onChange: @onFileInputChange

      br null

      div
        className: "row"
        div
          className: "col-xs-offset-1"
          a
            className: classNames("btn", "btn-primary", hide: @state.importStarted)
            onClick: @onSubmit
            disabled: !@isSubmittable()
            I18n.screw("Import starten")

module.exports = Component
