import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Panel from "exercises/editor/panel/index"
import Choice from "./choice"

class ChoiceQuestion extends React.Component
  constructor: (props) ->
    super(props)

  handleChoiceChange: (data) =>
    @props.onChange(choices: (@props.choices.map((item, i) => if data.id is i then Object.assign({}, item, data) else item)))

  handleShuffleChange: (data) =>
    @props.onChange(shuffle: !@props.shuffle)

  handleChoicesCountChange: (event) =>
    count = event.target.value
    @props.onChange(choices: [1..count].map((i) => @props.choices[i - 1] ? {selection: false}))

  handleKindChange: (event) =>
    kind = event.target.value
    choices = @props.choices.map((item) => Object.assign({}, item, contentData: null))
    @props.onChange({kind, choices})

  getChoiceColumns: =>
    count = @props.choices.length
    return 4 if count >= 7
    return 3 if count >= 5
    return 2 if count >= 3
    1

  getChoicesClassname: =>
    "columns-#{@getChoiceColumns()}"

  render: =>
    ReactDom.div null,
      React.createElement Panel,
        header: I18n.screw("Richtige Antworten anklicken.")
        ReactDom.div
          className: cx("choices", @getChoicesClassname())
          @props.choices.map (choice, i) =>
            React.createElement Choice,
              Object.assign({}, choice, kind: @props.kind, id: i, key: i, onChange: @handleChoiceChange)
      ReactDom.br null

      React.createElement Panel,
        header: I18n.screw("Einstellungen")
        React.createElement React.Fragment, null,
          ReactDom.div
            className: "form-group"
            ReactDom.div
              className: "form-inline"
              ReactDom.label
                className: "control-label"
                I18n.screw("Antworten")
              " "
              ReactDom.select
                className: "form-control"
                value: @props.choices.length
                onChange: @handleChoicesCountChange
                [1..12].map (i) =>
                  ReactDom.option
                    key: i
                    value: i
                    i
              " "
              ReactDom.select
                className: "form-control"
                value: @props.kind
                onChange: @handleKindChange
                ReactDom.option
                  value: "text"
                  I18n.screw("Texte")
                ReactDom.option
                  value: "media"
                  I18n.screw("Medien")
          ReactDom.div
            className: "form-group"
            ReactDom.div
              className: "checkbox"
              ReactDom.label null,
                ReactDom.input
                  type: "checkbox"
                  checked: @props.shuffle
                  onChange: @handleShuffleChange
                I18n.screw("Antworten zufällig anordnen")

export default ChoiceQuestion
