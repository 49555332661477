React = require("react")
ReactDOM = require("react-dom")

resolveComponent = (id) ->
  components =
    "imports-new": require("imports/new")
    "dictionary": require("dictionary/container")
    "audio-recorder": require("audio-recorder/container")
    "exercise-editor": require("exercises/editor/container")
    "assignment-question-main": require("exercises/assignment_question/main")
    "assignment-question-sidebar": require("exercises/assignment_question/sidebar")
  Component = components[id] or throw "Component #{id} not found!"
  Component = Component.default if Component.default?
  Component

mountComponent = (Component, props, container) ->
  ReactDOM.render(React.createElement(Component, props), container)

require("dictionary/loader")

$ ->
  $("component").each ->
    Component = resolveComponent($(@).attr("id"))
    mountComponent(Component, $(@).data("props"), @)
