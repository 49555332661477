import React from "react"
import ReactDom from "react-dom-factories"

import {EditorState, RichUtils} from "draft-js"
import {getSelectionEntity} from "draftjs-utils"

import classNames from "classnames"

class GapButton extends React.Component
  handleMouseDown: (e) =>
    e.preventDefault()

  isActive: =>
    entityKey = getSelectionEntity(@props.getEditorState())
    return unless entityKey?
    editorState = @props.getEditorState()
    contentState = editorState.getCurrentContent()
    contentState.getEntity(entityKey).type is "GAP"

  handleClick: (e) =>
    e.preventDefault()
    editorState = @props.getEditorState()
    if @isActive()
      entityKey = null
    else
      contentState = editorState.getCurrentContent()
      contentStateWithEntity = contentState.createEntity("GAP", "MUTABLE")
      entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      editorState = EditorState.set(editorState, currentContent: contentStateWithEntity)
    editorState = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey)
    @props.setEditorState(editorState)

  render: =>
    {theme} = @props
    ReactDom.div
      className: theme.buttonWrapper
      onMouseDown: @handleMouseDown
      ReactDom.button
        className: classNames(theme.button, "#{theme.active}": @isActive())
        style:
          paddingTop: 0
          width: "auto"
          fontWeight: "bold"
        onClick: @handleClick
        "Textlücke"

export default GapButton
