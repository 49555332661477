import React from "react"
import classNames from "classnames"
import {div, span, label, input} from "react-dom-factories"
import Wrapper from "./wrapper"
import _ from "lodash"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      changed: false

  componentWillMount: ->
    @id = "checkbox-#{_.uniqueId()}"

  value: =>
    @props.value ? []

  handleChange: (e) =>
    id = e.target.value
    if e.target.checked
      selected = @value().concat([id])
    else
      selected = _.without(@value(), id)
    @props.onChange(selected)

  getCollection: =>
    {collection} = @props
    return [] unless Array.isArray(collection) && collection.length > 0
    return collection if typeof collection[0] is "object"
    collection.map((i) -> [i, i])

  render: ->
    React.createElement Wrapper,
      Object.assign({}, @props, htmlFor: @id, changed: @state.changed)
      for item in @getCollection()
        span
          key: item[1]
          className: "checkbox"
          label
            htmlFor: "#{@id}-#{item[1]}"
            input
              id: "#{@id}-#{item[1]}"
              type: "checkbox"
              value: item[1]
              checked: item[1] in @value()
              onChange: @handleChange
              disabled: @props.disabled
            " "
            item[0]

export default Component
