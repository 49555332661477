React = require("react")
classNames = require("classnames")

{table, tbody, tr, td} = require("react-dom-factories")

class Component extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: =>
    table
      className: "table table-condensed table-striped"
      tbody null,
        for item, i in @props.items
          tr
            key: i
            className: classNames(selected: i is @props.highlightedRowIndex)
            td null,
              item.word
            td null,
              item.meaning

module.exports = Component
