import React from "react"
import ReactDom from "react-dom-factories"

GapDecorator =
  strategy: (contentBlock, callback, contentState) =>
    matcher = (character) =>
      entityKey = character.getEntity()
      entityKey? && contentState.getEntity(entityKey).getType() is "GAP"
    contentBlock.findEntityRanges(matcher, callback)

  component: (props) =>
    React.createElement "span",
      style:
        border: "1px solid #f00"
      props.children

export default GapDecorator
