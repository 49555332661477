import React from "react"
import classNames from "classnames"
import {div, textarea} from "react-dom-factories"
import Wrapper from "./wrapper"
import HtmlEditor from "exercises/editor/html_editor/index"
import _ from "lodash"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @id = _.uniqueId("input-")
    @state =
      changed: false

  onChange: (value) =>
    @setState(changed: true)
    @props.onChange(value)

  render: =>
    React.createElement Wrapper,
      Object.assign({}, @props, htmlFor: @id, changed: @state.changed)
      div
        className: "form-control"
        style:
          height: "auto"
        React.createElement HtmlEditor,
          id: @id
          key: "input"
          disabled: @props.readonly
          value: @props.value ? ""
          placeholder: @props.placeholder
          onChange: @onChange
          onBlur: @props.onUpdate

export default Component
