import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

ColorDecorator =
  strategy: (contentBlock, callback, contentState) =>
    matcher = (character) =>
      entityKey = character.getEntity()
      entityKey? && contentState.getEntity(entityKey).getType() is "COLOR"
    contentBlock.findEntityRanges(matcher, callback)

  component: (props) =>
    {color} = props.contentState.getEntity(props.entityKey).getData()
    React.createElement "span",
      className: cx("colored-text", color)
      props.children

export default ColorDecorator
