import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import MediaDropbox from "exercises/editor/media_dropbox/index"

class Media extends React.Component
  render: =>
    ReactDom.div
      className: cx("media")
      switch @props.kind
        when "text"
          ReactDom.div
            className: cx("text")
            dangerouslySetInnerHTML:
              __html: @props.contentData
        when "media"
          React.createElement MediaDropbox,
            mimeType: @props.contentData?.mimeType
            url: @props.contentData?.url

export default Media
