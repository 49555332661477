import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import HtmlEditor from "exercises/editor/html_editor/index"
import Tristate from "exercises/editor/tristate/index"
import MediaDropbox from "exercises/editor/media_dropbox/index"

class Choice extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      url: props.url

  handleSelectionChange: (value) =>
    @props.onChange(id: @props.id, selection: value)

  handleTextChange: (value) =>
    @props.onChange(id: @props.id, contentData: value)

  handleMediaChange: (data) =>
    @props.onChange(id: @props.id, contentData: data)

  render: =>
    ReactDom.div
      className: cx("choice")
      React.createElement Tristate,
        className: cx("tristate")
        value: @props.selection
        onChange: @handleSelectionChange
      ReactDom.div
        className: cx("media")
        switch @props.kind
          when "text"
            ReactDom.div
              className: cx("text")
              React.createElement HtmlEditor,
                value: @props.contentData
                onChange: @handleTextChange
          when "media"
            React.createElement MediaDropbox,
              mimeType: @props.contentData?.mimeType
              url: @props.contentData?.url
              onChange: @handleMediaChange

export default Choice
