import React, {useState, useEffect} from "react"
import ReactDom from "react-dom-factories"

import {HashRouter as Router, Switch, Route, Link, useHistory} from "react-router-dom"

import classNames from "classnames"

import TopNavigation from "./container/top_navigation"

import _ from "lodash"

import QuestionGroup from "./container/question_group"

import {DndProvider} from "react-dnd"
import {HTML5Backend} from "react-dnd-html5-backend"

import Axios from "axios"

import HtmlInput from "lib/form/html_input"
import TextInput from "lib/form/text_input"
import StringInput from "lib/form/string_input"
import SelectInput from "lib/form/select_input"
import BooleanInput from "lib/form/boolean_input"
import CheckboxesInput from "lib/form/checkboxes_input"
import InputWrapper from "lib/form/wrapper"
import Link1 from "lib/link"

import {ModalProvider} from "react-modal-hook"

import ToleranceMap from "./tolerance_map"
# import {Resizable} from "react-resizable"
# import ResizableBox from "lib/resizable_box"
# import styles from "react-resizable/css/styles.css"

Editor = (props) =>
  [pendingChanges, setPendingChanges] = useState(false)

  [exerciseId, setExerciseId] = useState(props.id)
  [isTest, setIsTest] = useState(props.isTest)
  [submitUrl, setSubmitUrl] = useState(props.submitUrl)
  [cancelUrl, setCancelUrl] = useState(props.cancelUrl)
  [name, setName] = useState(props.name)
  [subject, setSubject] = useState(props.subject)
  [topic, setTopic] = useState(props.topic)
  [chapter, setChapter] = useState(props.chapter)
  [learningModuleId, setLearningModuleId] = useState(props.learningModuleId)
  [remarks, setRemarks] = useState(props.remarks)
  [level, setLevel] = useState(props.level)
  [teachingMaterial, setTeachingMaterial] = useState(props.teachingMaterial)
  [expertise, setExpertise] = useState(props.expertise)
  [expertiseCode, setExpertiseCode] = useState(props.expertiseCode)
  [goals, setGoals] = useState(props.goals)
  [dictionary, setDictionary] = useState(props.dictionary)
  [tolerance, setTolerance] = useState(props.tolerance)
  [correctionFlags, setCorrectionFlags] = useState(props.correctionFlags)
  [correctionFlagsEditable, setCorrectionFlagsEditable] = useState(props.correctionFlagsEditable)
  [allowedLearningModes, setAllowedLearningModes] = useState(props.allowedLearningModes)
  [questionGroups, setQuestionGroups] = useState(
    props.questionGroups.map (group, i) =>
      id: _.uniqueId()
      name: group.name
      questions: group.questions.map (question, i) =>
        Object.assign({}, question, id: _.uniqueId())
  )
  [formErrors, setFormErrors] = useState({})

  useEffect =>
    updateQuestionGroups(questionGroups)
  , []

  useEffect =>
    window.onbeforeunload = (event) =>
      return unless pendingChanges
      event.returnValue = I18n.screw("Sie haben Ihre Änderungen noch nicht gespeichert! Sind Sie sicher, dass Sie die Seite verlassen möchten?")
    => window.onbeforeunload = null

  history = useHistory()

  updateQuestionGroups = (newQuestionGroups) =>
    newToleranceMap = {}
    for group in newQuestionGroups
      for question in group.questions
        continue unless question.type is "WritingQuestion"
        for answer in question.parameters.answers
          continue if answer is ""
          newToleranceMap[answer] = tolerance[answer] ? []
    setQuestionGroups(newQuestionGroups)
    setTolerance(newToleranceMap)

  markPendingChanges = (fn) =>
    =>
      setPendingChanges(true)
      fn.apply(@, arguments)

  handleReset = =>
    setName(props.name)
    setSubject(props.subject)
    setTopic(props.topic)
    setChapter(props.chapter)
    setLearningModuleId(props.learningModuleId)
    setRemarks(props.remarks)
    setLevel(props.level)
    setTeachingMaterial(props.teachingMaterial)
    setExpertise(props.expertise)
    setExpertiseCode(props.expertiseCode)
    setGoals(props.goals)
    setDictionary(props.dictionary)
    setTolerance(props.tolerance)
    setCorrectionFlags(props.correctionFlags)
    setCorrectionFlagsEditable(props.correctionFlagsEditable)
    setAllowedLearningModes(props.allowedLearningModes)
    setQuestionGroups(
      props.questionGroups.map (group, i) =>
        id: _.uniqueId()
        name: group.name
        questions: group.questions.map (question, i) =>
          Object.assign({}, question, id: _.uniqueId())
    )
    setFormErrors({})
    setPendingChanges(false)
    history.push("/")

  handleSubmit = =>
    payload =
      form:
        is_test: isTest
        name: name
        chapter: chapter
        subject: subject
        topic: topic
        learning_module_id: learningModuleId
        level: level
        goals: goals
        teaching_material: teachingMaterial
        expertise: expertise
        expertise_code: expertiseCode
        remarks: remarks
        dictionary: dictionary
        tolerance: tolerance
        correction_flags: correctionFlags
        correction_flags_editable: correctionFlagsEditable
        allowed_learning_modes: allowedLearningModes
        question_groups: questionGroups

    config =
      headers:
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")

    request = Axios[if exerciseId? then "put" else "post"](submitUrl, payload, config)
    request.then(({data}) =>
      setPendingChanges(false)
      if data.errors?
        setFormErrors(data.errors)
        bootbox.alert(title: I18n.screw("Hinweis"), message: I18n.screw("Nicht alle erforderlichen Felder wurden ausgefüllt, bitte überprüfen Sie diese!"))
      else
        setFormErrors({})
        if !exerciseId?
          setExerciseId(data.id)
          setSubmitUrl(data.submitUrl)
          setCancelUrl(data.cancelUrl)
        message = if isTest then I18n.screw("Test gespeichert!") else I18n.screw("Übung gespeichert!")
        bootbox.alert(title: I18n.screw("Hinweis"), message: message)
    ).catch((response) =>
      bootbox.alert(title: I18n.screw("Interner Fehler"), message: I18n.screw("Fehler beim Speichern, bitte überprüfen Sie ihre Eingaben!"))
    )

  setAllowedLearningModes1 = (newModes) =>
    newMode = newModes.filter((x) => !allowedLearningModes.includes(x))[0]
    newModes = if newMode is "examination_single_try" then ["examination_single_try"] else newModes.filter((x) => x isnt "examination_single_try")
    setAllowedLearningModes(newModes)

  availableAllowedLearningModes = []
  if !isTest
    availableAllowedLearningModes.push([I18n.screw("Falsche Antworten anzeigen"), "show_wrong_answers"])
    availableAllowedLearningModes.push([I18n.screw("Jede Antwort anzeigen"), "show_all_answers"])
    availableAllowedLearningModes.push([I18n.screw("Zwei Chancen"), "two_chances"])
  availableAllowedLearningModes.push([I18n.screw("Prüfungsmodus"), "examination"])
  availableAllowedLearningModes.push([I18n.screw("Prüfungsmodus (nur 1 Versuch)"), "examination_single_try"])

  React.createElement React.Fragment, null,
    ReactDom.h1 null,
      if isTest
        if exerciseId?
          I18n.screw("Test bearbeiten: %{name}", name: "#{subject} / #{name}")
        else
          I18n.screw("Neuen Test erstellen")
      else
        if exerciseId?
          I18n.screw("Übung bearbeiten: %{name}", name: "#{subject} / #{name}")
        else
          I18n.screw("Neue Übung erstellen")
    if isTest
      ReactDom.div
        className: "page-help"
        dangerouslySetInnerHTML:
          __html: I18n.screw("Hier können Sie einen neuen Test aus bestehenden Übungen erstellen. Die Anzahl Fragen ist bei jeder hinzugefügten Übung frei bestimmbar. Die <strong>Felder mit *</strong> müssen ausgefüllt werden. Speichern Sie den Test in ein bestehendes Modul oder erstellen Sie ein neues Modul dafür. Klicken Sie nach <strong>\"Test speichern\"</strong> auf den Button <strong>\"Zum Testgenerator\"</strong> und starten Sie im neu erstellten Test den <strong>Testgenerator</strong>.")
    React.createElement Switch, null,
      React.createElement Route,
        path: "/"
        exact: true
        ({match}) =>
          React.createElement TopNavigation,
            questionGroups: questionGroups
            onQuestionGroupsUpdate: markPendingChanges(updateQuestionGroups)
            ReactDom.div
              className: "form-horizontal"
              ReactDom.br null
              React.createElement StringInput,
                name: I18n.screw("Name")
                value: name
                required: true
                errors: formErrors.name
                onChange: markPendingChanges(setName)
              React.createElement SelectInput,
                name: I18n.screw("Lernmodul")
                collection: props.availableLearningModules
                value: learningModuleId
                required: true
                errors: formErrors.learningModuleId
                onChange: markPendingChanges(setLearningModuleId)
                allowCustomInput: true
                promptTextCreator: (label) =>
                  React.createElement React.Fragment, null,
                    ReactDom.strong null,
                      I18n.screw("Neues Lernmodul erstellen:")
                    label
              React.createElement StringInput,
                name: I18n.screw("Fachgebiet")
                value: subject
                required: true
                errors: formErrors.subject
                onChange: markPendingChanges(setSubject)
              React.createElement StringInput,
                name: I18n.screw("Kapitel")
                value: chapter
                required: !isTest
                errors: formErrors.chapter
                onChange: markPendingChanges(setChapter)
              React.createElement StringInput,
                name: I18n.screw("Thema")
                value: topic
                required: !isTest
                errors: formErrors.topic
                onChange: markPendingChanges(setTopic)
              React.createElement StringInput,
                name: I18n.screw("Lernziele")
                value: goals
                onChange: markPendingChanges(setGoals)
              React.createElement StringInput,
                name: I18n.screw("Niveau")
                value: level
                onChange: markPendingChanges(setLevel)
              React.createElement StringInput,
                name: I18n.screw("Lehrmittel")
                value: teachingMaterial
                onChange: markPendingChanges(setTeachingMaterial)
              React.createElement StringInput,
                name: I18n.screw("Kompetenzen")
                value: expertise
                onChange: markPendingChanges(setExpertise)
              React.createElement StringInput,
                name: I18n.screw("Kompetenz-Code")
                value: expertiseCode
                onChange: markPendingChanges(setExpertiseCode)
              React.createElement TextInput,
                name: I18n.screw("Bemerkungen")
                value: remarks
                onChange: markPendingChanges(setRemarks)
              React.createElement TextInput,
                name: I18n.screw("Wörterbuch")
                value: dictionary
                rows: 10
                onChange: markPendingChanges(setDictionary)
              React.createElement InputWrapper,
                name: I18n.screw("Toleranzwertliste")
                ReactDom.div
                  className: "form-text"
                  ReactDom.p null,
                    I18n.screw("Hier können Sie mögliche Alternativen für Antworten der Schreibaufgaben definieren.")
                  if Object.keys(tolerance).length is 0
                    ReactDom.p null,
                      if isTest then I18n.screw("Der Test enthält noch keine Schreibaufgaben.") else I18n.screw("Die Übung enthält noch keine Schreibaufgaben.")
                  React.createElement ToleranceMap,
                    map: tolerance
                    onChange: markPendingChanges(setTolerance)
              React.createElement CheckboxesInput,
                name: if isTest then I18n.screw("Korrekturmodus") else I18n.screw("Standardkorrekturmodus")
                collection: [
                  [I18n.screw("Leerzeichen beachten"), "respect_whitespaces"],
                  [I18n.screw("Gross- und Kleinschreibung beachten"), "respect_case"],
                  [I18n.screw("Akzente beachten"), "respect_accents"],
                  [I18n.screw("Satzzeichen beachten"), "respect_punctuation"],
                ]
                value: correctionFlags
                errors: formErrors.correctionFlags
                onChange: markPendingChanges(setCorrectionFlags)
              if !isTest
                React.createElement BooleanInput,
                  name: I18n.screw("Korrekturmodus änderbar")
                  value: correctionFlagsEditable
                  errors: formErrors.correctionFlagsEditable
                  onChange: markPendingChanges(setCorrectionFlagsEditable)
              React.createElement CheckboxesInput,
                name: I18n.screw("Erlaubte Lernmodi")
                collection: availableAllowedLearningModes
                value: allowedLearningModes
                errors: formErrors.allowedLearningModes
                onChange: markPendingChanges(setAllowedLearningModes1)
      React.createElement Route,
        path: "/questionGroups/:questionGroupId"
        ({match}) =>
          {questionGroupId} = match.params
          questionGroup = questionGroups.find((item) => item.id is questionGroupId)
          if questionGroup?
            React.createElement TopNavigation,
              questionGroup: questionGroup
              questionGroups: questionGroups
              onQuestionGroupsUpdate: markPendingChanges(updateQuestionGroups)
              React.createElement QuestionGroup,
                match: match
                questionGroup: questionGroup
                questionGroups: questionGroups
                onQuestionGroupsUpdate: markPendingChanges(updateQuestionGroups)
          else
            questionGroup ?= questionGroups[0]
            if questionGroup?
              history.replace("/questionGroups/#{questionGroup.id}")
            else
              history.replace("/")

    ReactDom.br null
    ReactDom.div
      className: "form-group"
      React.createElement Link1,
        className: classNames("btn", "btn-primary")
        onClick: handleSubmit
        if isTest
          I18n.screw("Test speichern")
        else
          I18n.screw("Übung speichern")
      " "
      React.createElement Link1,
        className: classNames("btn", "btn-danger")
        confirm: true
        onClick: handleReset
        I18n.screw("Änderungen verwerfen")
      " "
      ReactDom.a
        className: classNames("btn", "btn-default")
        href: cancelUrl
        if isTest && exerciseId?
          I18n.screw("Zum Testgenerator")
        else
          I18n.screw("Bearbeiten-Modus verlassen")

Container = (props )=>
  React.createElement ModalProvider, null,
    React.createElement DndProvider,
      backend: HTML5Backend
      React.createElement Router, null,
        React.createElement Editor, props

export default Container
