import React, {useRef} from "react"
import ReactDom from "react-dom-factories"

import {Switch, Route, Link} from "react-router-dom"

import classNames from "classnames"

import {useDrag, useDrop} from "react-dnd"

Question = (props) =>
  ref = useRef(null)

  [{canDrop, isOver}, drop] = useDrop
    accept: "Question"

    hover: (item, monitor) =>
      return if !ref.current?

      dragIndex = item.index
      hoverIndex = props.index
      return if dragIndex is hoverIndex

      props.onMove(dragIndex, hoverIndex)
      item.index = hoverIndex

    drop: (item, monitor) =>
      props.onMoveCompleted()
      target: "Question"

    collect: (monitor) =>
      isOver: monitor.isOver()
      canDrop: monitor.canDrop()

  [{isDragging}, drag] = useDrag
    item:
      type: "Question"
      id: props.id
      index: props.index

    end: (item, monitor) =>
      return if monitor.didDrop() && monitor.getDropResult().target == "Question"
      props.onMoveCanceled()

    collect: (monitor) =>
      isDragging: monitor.isDragging()

  drag(drop(ref))

  title = {
    InfoQuestion: I18n.screw("Information")
    WritingQuestion: I18n.screw("Schreibaufgabe")
    GapsQuestion: I18n.screw("Textlücken")
    ChoiceQuestion: I18n.screw("Multiple Choice")
    AssignmentQuestion: I18n.screw("Zuordnung")
    RecordingQuestion: I18n.screw("Sprachproduktion")
  }[props.type]

  ReactDom.li
    ref: ref
    title: title
    className: classNames(active: props.active)
    style:
      opacity: if isDragging then 0 else 1
    React.createElement Link,
      to: "/questionGroups/#{props.questionGroupId}/questions/#{props.id}"
      I18n.screw("Frage %{index}", index: props.index + 1)

export default Question
