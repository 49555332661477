import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Media from "./media"

class Choice extends React.Component
  render: =>
    ReactDom.div
      className: cx("box")
      style:
        left: "#{@props.box.x}px"
        top: "#{@props.box.y}px"
        width: "#{@props.box.width}px"
        height: "#{@props.box.height}px"
      React.createElement Media,
        @props

export default Choice
