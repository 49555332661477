import React from "react"
import ReactDom from "react-dom-factories"

import Axios from "axios"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

class MediaDropbox extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      isDropTarget: 0
      upload: null

  handleDragEnter: (event) =>
    @setState(({isDropTarget}) => isDropTarget: isDropTarget + 1)

  handleDragLeave: (event) =>
    @setState(({isDropTarget}) => isDropTarget: Math.max(0, isDropTarget - 1))

  handleDragOver: (event) =>
    event.preventDefault()

  handleDrop: (event) =>
    return unless @props.onChange?

    event.preventDefault()
    @setState(isDropTarget: 0, upload: "pending")

    data = new FormData()
    data.append("file", event.dataTransfer.files[0])
    config =
      onUploadProgress: (progressEvent) =>
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      headers:
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
    Axios.post("/media_items", data, config)
      .then (response) =>
        {data} = response
        @setState(upload: null)
        @props.onChange(id: data.id, url: data.url, mimeType: data.mime_type)
      .catch (error) =>
        @setState(upload: "failed")

  handleReset: =>
    @props.onChange(null)

  render: =>
    ReactDom.div
      className: cx("container", @props.className, isDropTarget: @state.isDropTarget > 0)
      onDrop: @handleDrop
      onDragOver: @handleDragOver
      onDragEnter: @handleDragEnter
      onDragLeave: @handleDragLeave
      switch @props.mimeType
        when "audio/mpeg"
          ReactDom.div
            className: cx("dragWrapper")
            ReactDom.audio
              key: @props.url
              controls: true
              ReactDom.source
                type: @props.mimeType
                src: @props.url
              I18n.screw("Dein Browser unterstützt dieses Audio-Format nicht.")
        when "video/mp4"
          ReactDom.video
            key: @props.url
            controls: true
            ReactDom.source
              type: @props.mimeType
              src: @props.url
            I18n.screw("Dein Browser unterstützt dieses Video-Format nicht.")
        when "image/jpeg", "image/png", "image/gif"
          ReactDom.img
            src: @props.url
        else
          if @props.onChange?
            ReactDom.div
              className: cx("dropZone")
              switch @state.upload
                when "pending"
                  I18n.screw("Upload wird ausgeführt, bitte warten...")
                when "failed"
                  I18n.screw("Fehler beim Upload. Ist die Quelldatei beschädigt?")
                else
                  I18n.screw("Bild, Audio oder Video hier per Drag'n Drop ablegen")
      if @props.mimeType? && @props.onChange?
        ReactDom.div
          className: cx("btn", "btn-xs", "btn-danger", "resetButton")
          onClick: @handleReset
          "x"

export default MediaDropbox
