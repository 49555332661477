import React, {useState} from "react"
import ReactDom from "react-dom-factories"
import Modal from "./modal"

ConfirmationModal = (props) =>
  footer =
    React.createElement React.Fragment, null,
      ReactDom.button
        className: "btn btn-default"
        onClick: props.onCancel
        I18n.screw("Abbrechen")
      ReactDom.button
        className: "btn btn-primary"
        onClick: props.onConfirm
        I18n.screw("Bestätigen")

  React.createElement Modal,
    Object.assign({}, props, footer: footer)

export default ConfirmationModal
