import React, {useState, useEffect} from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames"

ToleranceMap = (props) =>
  onChange = (key, index, e) =>
    map = Object.assign({}, props.map)
    map[key][index] = e.target.value
    props.onChange(map)

  ReactDom.div null,
    Object.keys(props.map).sort((a, b) => a.localeCompare(b, undefined, sensitivity: "base")).map (key) =>
      ReactDom.div
        key: key
        className: "form-group"
        ReactDom.div
          className: "col-md-4"
          ReactDom.input
            className: "form-control"
            type: "text"
            disabled: true
            value: key
        for i in [0..2]
          ReactDom.div
            key: i
            className: "col-md-4"
            ReactDom.input
              className: "form-control"
              type: "text"
              value: props.map[key]?[i] ? ""
              onChange: onChange.bind(@, key, i)

export default ToleranceMap
