import React, {useState} from "react"
import ReactDom from "react-dom-factories"
import {useModal} from "react-modal-hook"
import ConfirmationModal from "./modal_confirm"

Link = (props) =>
  [originalEvent, setOriginalEvent] = useState()

  [showModal, hideModal] = useModal =>
    React.createElement ConfirmationModal,
      title: I18n.screw("Hinweis")
      onConfirm: =>
        handleClick1(originalEvent)
        hideModal()
      onCancel: hideModal
      I18n.screw("Sind Sie sicher?")
  , [props.onClick, props.target]

  getTarget: =>
    return unless props.target? && props.target isnt ""
    props.target

  handleClick = (e) =>
    return e.preventDefault() if props.disabled
    if props.confirm?
      e.preventDefault()
      setOriginalEvent(e)
      showModal()
      return
    handleClick1(e)

  handleClick1 = (e) =>
    return props.onClick(e) if props.onClick?
    e.preventDefault()
    target = if e.ctrlKey then "_blank" else getTarget()
    navigateTo(target)

  navigateTo = (target) =>
    return openInWindow(target) if target?
    openInWindow("_self")

  openInWindow = (target) =>
    window.open(props.href, target)

  ReactDom.a
    className: props.className
    href: props.href
    onClick: handleClick
    props.children

export default Link
