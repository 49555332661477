import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Choice from "./choice"

import Events from "src/events"

class AssignmentQuestion extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      bbox: null
      targetsContainerBbox: null

  componentDidMount: =>
    @setBbox()
    @setBboxTimeout = setInterval(@setBbox, 250)
    window.addEventListener("scroll", @setBbox)
    Events.on "targets-container-bbox:update", (targetsContainerBbox) =>
      @setState({targetsContainerBbox})

  componentWillUnmount: =>
    clearTimeout(@setBboxTimeout)
    window.removeEventListener("scroll", @setBbox)
    Events.off("targets-container-bbox:update")

  setBbox: =>
    bbox = @containerEl.getBoundingClientRect()
    bbox.height = bbox.width * @props.targetsBboxAspectRatio
    @setState({bbox})

  handleDraggableChange: (data) =>
    @props.onChange(draggables: (@props.draggables.map((item, i) => if data.id is i then Object.assign({}, item, data) else item)))

  handleDraggableDrop: (data) =>
    {x, y} = data.contentBox
    return if x >= 0 && x <= 100 && y >= 0 && y <= 100
    Object.assign(data.contentBox, x: null, y: null)
    @handleDraggableChange(data)

  transformedBox: (box) =>
    x = if box.x? then box.x / 100 * @state.targetsContainerBbox.width + (@state.targetsContainerBbox.x - @state.bbox.x)
    y = if box.y? then box.y / 100 * @state.targetsContainerBbox.height + (@state.targetsContainerBbox.y - @state.bbox.y)
    width = box.width / 100 * @state.targetsContainerBbox.width
    height = width * box.aspectRatio
    {x, y, width, height}

  render: =>
    ReactDom.div
      className: cx("sidebar")
      ref: (el) => @containerEl = el
      if @state.bbox? && @state.targetsContainerBbox?
        @props.draggables.map (draggable, i) =>
          React.createElement Choice,
            key: i
            id: i
            title: "L"
            kind: @props.draggablesKind
            originalBox: draggable.contentBox
            box: @transformedBox(draggable.contentBox)
            contentData: draggable.contentData
            targetsContainerBbox: @state.targetsContainerBbox
            onChange: @handleDraggableChange
            onDrop: @handleDraggableDrop

export default AssignmentQuestion
