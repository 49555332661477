import React, {useRef} from "react"
import ReactDom from "react-dom-factories"

import {Link, withRouter} from "react-router-dom"

import classNames from "classnames"

import {useDrag, useDrop} from "react-dnd"

QuestionGroup = (props) =>
  ref = useRef(null)

  [{canDrop, isOver}, drop] = useDrop
    accept: ["QuestionGroup", "Question"]

    hover: (item, monitor) =>
      return if !ref.current?
      return if item.type is "Question"

      dragIndex = item.index
      hoverIndex = props.index
      return if dragIndex is hoverIndex

      props.onMove(dragIndex, hoverIndex)
      item.index = hoverIndex

    drop: (item, monitor) =>
      switch item.type
        when "Question"
          props.onMoveQuestion(item.id, props.id)
        else
          props.onMoveCompleted()
      target: "QuestionGroup"

    collect: (monitor) =>
      isOver: monitor.isOver()
      canDrop: monitor.canDrop()

  [{isDragging}, drag] = useDrag
    item:
      type: "QuestionGroup"
      id: props.id
      index: props.index

    end: (item, monitor) =>
      return if monitor.didDrop() && monitor.getDropResult().target == "QuestionGroup"
      props.onMoveCanceled()

    collect: (monitor) =>
      isDragging: monitor.isDragging()

  drag(drop(ref))

  ReactDom.li
    ref: ref
    className: classNames(active: props.active)
    style:
      opacity: if isDragging then 0 else 1
    React.createElement Link,
      to: "/questionGroups/#{props.id}"
      title: props.title
      I18n.screw("Lernschritt %{index}", index: props.index + 1)

export default withRouter(QuestionGroup)
