import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

class Panel extends React.Component
  render: =>
    ReactDom.div
      className: cx("panel")
      ReactDom.div
        className: cx("header")
        @props.header
      ReactDom.div
        className: cx("body")
        @props.children

export default Panel
