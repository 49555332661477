import React from "react"
import ReactDom from "react-dom-factories"

import HtmlEditor from "exercises/editor/html_editor/index"
import Panel from "exercises/editor/panel/index"

class RecordingQuestion extends React.Component
  constructor: (props) ->
    super(props)

  onChange: (value) =>
    @props.onChange(outline: value)

  render: =>
    React.createElement Panel,
      header: I18n.screw("Lies / Lesen Sie den Text aufmerksam durch.")
      React.createElement HtmlEditor,
        value: @props.outline
        onChange: @onChange

export default RecordingQuestion
