import React from "react"
import ReactDom from "react-dom-factories"

import {EditorState, CompositeDecorator, RichUtils} from "draft-js"
import Editor, {createEditorStateWithText} from "draft-js-plugins-editor"
import {stateFromHTML} from "draft-js-import-html"
import {stateToHTML} from "draft-js-export-html"

import createInlineToolbarPlugin, {Separator} from "draft-js-inline-toolbar-plugin"
import "draft-js-inline-toolbar-plugin/lib/plugin.css"
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from "draft-js-buttons"

import GapButton from "./gap_button"
import GapDecorator from "./gap_decorator"

import ColorButton from "./color_button"
import ColorDecorator from "./color_decorator"

import createInlineStyleButton from "draft-js-buttons/lib/utils/createInlineStyleButton"

MonospaceButton = createInlineStyleButton
  style: "MONOSPACE"
  children:
    ReactDom.div
      style:
        marginTop: "-4px"
        fontWeight: "bold"
      "P"

SuperscriptButton = createInlineStyleButton
  style: "SUPERSCRIPT"
  children:
    ReactDom.span
      className: "fa fa-superscript"

SubscriptButton = createInlineStyleButton
  style: "SUBSCRIPT"
  children:
    ReactDom.span
      className: "fa fa-subscript"

customStyleMap =
  "MONOSPACE":
    fontFamily: '"Courier New", Courier, monospace'
  "SUPERSCRIPT":
    verticalAlign: "super"
  "SUBSCRIPT":
    verticalAlign: "sub"

class HtmlEditor extends React.Component
  constructor: (props) ->
    super(props)

    options =
      customInlineFn: (element, {Style, Entity}) =>
        switch
          when element.tagName is "SPAN" && element.className is "gap"
            Entity("GAP", {})
          when element.tagName is "SPAN" && element.className in ["red", "orange", "blue", "green", "black"]
            Entity("COLOR", color: element.className)
          when element.tagName is "SPAN" && element.className is "monospace"
            Style("MONOSPACE")
          when element.tagName is "SUP"
            Style("SUPERSCRIPT")
          when element.tagName is "SUB"
            Style("SUBSCRIPT")

    @state =
      editorState: EditorState.createWithContent(stateFromHTML(@props.value ? "", options))
      inlineToolbarPlugin: createInlineToolbarPlugin()

  updateHtml: =>
    options =
      inlineStyles:
        MONOSPACE:
          element: "span"
          attributes:
            class: "monospace"
        SUPERSCRIPT:
          element: "sup"
        SUBSCRIPT:
          element: "sub"
      entityStyleFn: (entity) =>
        switch entity.get("type")
          when "GAP"
            data = entity.getData()
            element: "span"
            attributes:
              class: "gap"
          when "COLOR"
            data = entity.getData()
            element: "span"
            attributes:
              class: data.color
    html = stateToHTML(@state.editorState.getCurrentContent(), options)
    @props.onChange(html)

  onEditorStateChange: (editorState) =>
    @setState({editorState}, @updateHtml)

  renderToolbar: (externalProps) =>
    ReactDom.div
      style:
        whiteSpace: "nowrap"
      React.createElement(BoldButton, externalProps)
      React.createElement(ItalicButton, externalProps)
      React.createElement(UnderlineButton, externalProps)
      React.createElement(MonospaceButton, externalProps)
      React.createElement(Separator, externalProps)
      React.createElement(UnorderedListButton, externalProps)
      React.createElement(OrderedListButton, externalProps)
      React.createElement(Separator, externalProps)
      React.createElement(SuperscriptButton, externalProps)
      React.createElement(SubscriptButton, externalProps)
      ReactDom.br null
      ["red", "orange", "blue", "green", "black"].map (color) =>
        React.createElement(ColorButton, Object.assign({key: color, color: color}, externalProps))
      if @props.gapsEnabled
        React.createElement React.Fragment, null,
          React.createElement(Separator, externalProps)
          React.createElement(GapButton, externalProps)

  render: =>
    React.createElement React.Fragment, null,
      React.createElement Editor,
        className: @props.className
        editorState: @state.editorState
        placeholder: @props.placeholder
        plugins: [@state.inlineToolbarPlugin]
        customStyleMap: customStyleMap
        decorators: [GapDecorator, ColorDecorator]
        onChange: @onEditorStateChange
      React.createElement(@state.inlineToolbarPlugin.InlineToolbar, {}, @renderToolbar)

export default HtmlEditor
