import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import HtmlEditor from "exercises/editor/html_editor/index"
import MediaDropbox from "exercises/editor/media_dropbox/index"

import Draggable from "react-draggable"

import {Resizable} from "react-resizable"
import resizableStyles from "react-resizable/css/styles.css"

class Choice extends React.Component
  handleTextChange: (value) =>
    @props.onChange(id: @props.id, contentData: value)

  handleMediaChange: (data) =>
    @props.onChange(id: @props.id, contentData: data)

  calulateBboxPosition: (event) =>
    x = (event.clientX - @props.targetsContainerBbox.x) / @props.targetsContainerBbox.width * 100
    y = (event.clientY - @props.targetsContainerBbox.y) / @props.targetsContainerBbox.height * 100
    {x, y}

  handleDrag: (event, data) =>
    contentBox = Object.assign({}, @props.originalBox, @calulateBboxPosition(event))
    @props.onChange(id: @props.id, contentBox: contentBox)

  handleDrop: (event, data) =>
    return unless @props.onDrop?
    contentBox = Object.assign({}, @props.originalBox, @calulateBboxPosition(event))
    @props.onDrop(id: @props.id, contentBox: contentBox)

  handleResize: (event, data) =>
    contentBox = Object.assign({}, @props.originalBox)
    contentBox.width = data.size.width / @props.targetsContainerBbox.width * 100
    contentBox.aspectRatio = data.size.height / data.size.width
    @props.onChange(id: @props.id, contentBox: contentBox)

  render: =>
    React.createElement Draggable,
      handle: ".#{cx("drag-handle")}"
      onDrag: @handleDrag
      onStop: @handleDrop
      position:
        x: NaN
        y: NaN
      ReactDom.div
        className: cx("box", "box-relative": !@props.box.x?)
        style:
          left: if @props.box.x? then "#{@props.box.x}px"
          top: if @props.box.y? then "#{@props.box.y}px"
          width: "#{@props.box.width}px"
          height: "#{@props.box.height}px"
        ReactDom.div
          className: cx("box-title", "drag-handle")
          @props.title
        React.createElement Resizable,
          width: @props.box.width
          height: @props.box.height
          minConstraints: [20, 20]
          onResize: @handleResize
          ReactDom.div
            className: cx("media")
            switch @props.kind
              when "text"
                ReactDom.div
                  className: cx("text")
                  React.createElement HtmlEditor,
                    value: @props.contentData
                    onChange: @handleTextChange
              when "media"
                React.createElement MediaDropbox,
                  mimeType: @props.contentData?.mimeType
                  url: @props.contentData?.url
                  onChange: @handleMediaChange

export default Choice
