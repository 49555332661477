import React from "react"
import ReactDom from "react-dom-factories"

import VirtualSelect from "react-virtualized-select"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import InfoQuestion from "./info_question/index"
import WritingQuestion from "./writing_question/index"
import GapsQuestion from "./gaps_question/index"
import AssignmentQuestion from "./assignment_question/index"
import ChoiceQuestion from "./choice_question/index"
import RecordingQuestion from "./recording_question/index"

import MediaDropbox from "exercises/editor/media_dropbox/index"
import HtmlEditor from "exercises/editor/html_editor/index"
import Panel from "exercises/editor/panel/index"

import ResizePanel from "lib/resize_panel/index"
import Modal from "lib/modal"

QuestionTypes =
  InfoQuestion: InfoQuestion
  WritingQuestion: WritingQuestion
  GapsQuestion: GapsQuestion
  AssignmentQuestion: AssignmentQuestion
  ChoiceQuestion: ChoiceQuestion
  RecordingQuestion: RecordingQuestion

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @containerRef = React.createRef()
    @state =
      showCopyHelpTextModal: false

  handleDragOver: (event) =>
    event.preventDefault()

  handleDrop: (event) =>
    event.preventDefault()

  handlePanelResize: (width) =>
    totalWidth = $(@containerRef.current).outerWidth(true)
    return unless totalWidth?
    sidebarWidth = Math.round((1 - width / totalWidth) * 100, 2)
    @props.onUpdate({sidebarWidth})

  handleTitleChange: (value) =>
    @props.onUpdate(title: value)

  handleDescriptionChange: (value) =>
    @props.onUpdate(description: value)

  handleHelpChange: (value) =>
    @props.onUpdate(help: value)

  handleMediaChange: (data) =>
    @props.onUpdate(media: data)

  handleDataChange: (data) =>
    @props.onUpdate(parameters: Object.assign({}, @props.parameters, data))

  showCopyHelpTextModal: =>
    @setState(showCopyHelpTextModal: true)

  hideCopyHelpTextModal: =>
    @setState(showCopyHelpTextModal: false)

  handleCopyHelpText: =>
    @hideCopyHelpTextModal()
    @props.onCopyHelpText()

  handleCopyHelpTextToQuestionGroup: =>
    @hideCopyHelpTextModal()
    @props.onCopyHelpTextToQuestionGroup()

  render: =>
    ReactDom.div
      ref: @containerRef
      className: cx("container")
      onDragOver: @handleDragOver
      onDrop: @handleDrop
      ReactDom.div
        style:
          display: "flex"
        React.createElement ResizePanel,
          direction: "e"
          style:
            flexBasis: "#{100 - @props.sidebarWidth}%"
          onResize: @handlePanelResize
          React.createElement React.Fragment, null,
            React.createElement Panel,
              header: React.createElement HtmlEditor,
                value: @props.title
                onChange: @handleTitleChange
              React.createElement HtmlEditor,
                value: @props.description
                onChange: @handleDescriptionChange
            ReactDom.br null
            React.createElement QuestionTypes[@props.type].Main ? QuestionTypes[@props.type],
              Object.assign({}, @props.parameters, key: @props.sidebarWidth, onChange: @handleDataChange)
            ReactDom.br null
            React.createElement Panel,
              header:
                React.createElement React.Fragment, null,
                  I18n.screw("Hilfetext")
                  " ["
                  ReactDom.a
                    onClick: @showCopyHelpTextModal
                    I18n.screw("kopieren")
                  "]"
              React.createElement HtmlEditor,
                value: @props.help
                onChange: @handleHelpChange
        ReactDom.div
          className: cx("sidebar")
          ReactDom.div null,
            React.createElement MediaDropbox,
              className: cx("media")
              onChange: @handleMediaChange
              mimeType: @props.media?.mimeType
              url: @props.media?.url
          if QuestionTypes[@props.type].Sidebar?
            React.createElement QuestionTypes[@props.type].Sidebar,
              Object.assign({}, @props.parameters, key: @props.sidebarWidth, onChange: @handleDataChange)
      if @state.showCopyHelpTextModal
        React.createElement Modal,
          onCancel: @hideCopyHelpTextModal
          title: I18n.screw("Hilfetext kopieren")
          footer:
            React.createElement React.Fragment, null,
              ReactDom.button
                className: "btn btn-default"
                onClick: @hideCopyHelpTextModal
                I18n.screw("Abbrechen")
          ReactDom.div
            className: "text-center"
            ReactDom.div null,
              ReactDom.button
                className: "btn btn-default"
                onClick: @handleCopyHelpText
                I18n.screw("In alle Fragen dieser Übung kopieren")
            ReactDom.br()
            ReactDom.div null,
              ReactDom.button
                className: "btn btn-default"
                onClick: @handleCopyHelpTextToQuestionGroup
                I18n.screw("In alle Fragen dieses Lernschritts kopieren")

export default Component
