import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Draggable from "react-draggable"

import Media from "./media"

class Answer extends React.Component
  handleDrag: (event, data) =>
    bbox = @containerEl.getBoundingClientRect()
    x = (bbox.x - window.bbox.x) / window.bbox.width * 100
    y = (bbox.y - window.bbox.y) / window.bbox.height * 100
    window.draggables ?= {}
    window.draggables[@props.id] = {x, y}

  render: =>
    React.createElement Draggable,
      onDrag: @handleDrag
      ReactDom.div
        ref: (el) => @containerEl = el
        className: cx("draggable", "no-dictionary", "box", "box-relative": !@props.box.x?)
        style:
          left: if @props.box.x? then "#{@props.box.x}px"
          top: if @props.box.y? then "#{@props.box.y}px"
          width: "#{@props.box.width}px"
          height: "#{@props.box.height}px"
        React.createElement Media,
          @props

export default Answer
