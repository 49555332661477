import React, {useState} from "react"
import ReactDom from "react-dom-factories"

Modal = (props) =>
  React.createElement React.Fragment, null,
    ReactDom.div
      className: "modal-backdrop"
      style:
        opacity: "0.5"
    ReactDom.div
      className: "modal"
      style:
        display: "block"
      ReactDom.div
        className: "modal-dialog"
        ReactDom.div
          className: "modal-content"
          ReactDom.div
            className: "modal-header"
            ReactDom.button
              className: "close"
              type: "button"
              onClick: props.onCancel
              "×"
            ReactDom.h4
              className: "modal-title"
              props.title

          ReactDom.div
            className: "modal-body"
            props.children

          ReactDom.div
            className: "modal-footer"
            props.footer

export default Modal
