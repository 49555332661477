import React, {useRef, useState, useEffect} from "react"
import ReactDom from "react-dom-factories"

import {Switch, Route, Link, useHistory} from "react-router-dom"

import classNames from "classnames"

import Question from "../question/index"
import Question1 from "./question"

import Link1 from "lib/link"

import {useModal} from "react-modal-hook"
import Modal from "lib/modal"

import StringInput from "lib/form/string_input"
import RadioButtonsInput from "lib/form/radio_buttons_input"

import _ from "lodash"

Sidebar = (props) =>
  [questions, setQuestions] = useState(props.questions)

  history = useHistory()

  useEffect =>
    setQuestions(props.questions)
  , [props.questions]

  handleMoveQuestion = (dragIndex, hoverIndex) =>
    dragQuestion = questions[dragIndex]
    questions = questions.slice()
    questions.splice(dragIndex, 1)
    questions.splice(hoverIndex, 0, dragQuestion)
    setQuestions(questions)

  handleMoveQuestionCanceled = =>
    setQuestions(props.questions)

  handleMoveQuestionCompleted = =>
    props.onQuestionsUpdate(questions)

  [showAddQuestionModal, hideAddQuestionModal] = useModal =>
    [type, setType] = useState()
    React.createElement Modal,
      title: I18n.screw("Frage hinzufügen")
      footer:
        React.createElement React.Fragment, null,
          ReactDom.button
            className: "btn btn-default"
            onClick: hideAddQuestionModal
            I18n.screw("Abbrechen")
          React.createElement Link1,
            className: "btn btn-primary"
            disabled: !type?
            onClick: =>
              parameters = switch type
                when "WritingQuestion"
                  answers: []
                  expectedAnswersCount: 1
                  exactOrdering: false
                when "ChoiceQuestion"
                  kind: "text"
                  choices: [{selection: false}, {selection: false}]
                  shuffle: false
                when "AssignmentQuestion"
                  targetsKind: "text"
                  draggablesKind: "text"
                  exactPosition: false
                  targetsBboxAspectRatio: 0.3
                  targets: [{contentBox: {x: 0, y: 0, width: 25, aspectRatio: 1}}]
                  draggables: [{contentBox: {width: 25, aspectRatio: 0.5}}]
                else
                  {}
              question = Object.assign(id: _.uniqueId(), type: type, sidebarWidth: 30, parameters: parameters)
              props.onQuestionsUpdate(questions.concat([question]))
              hideAddQuestionModal()
              history.push("/questionGroups/#{props.questionGroupId}/questions/#{question.id}")
            I18n.screw("Hinzufügen")
      onCancel: hideAddQuestionModal
      ReactDom.div
        className: "form-horizontal"
        React.createElement RadioButtonsInput,
          name: I18n.screw("Typ")
          collection: [
            [I18n.screw("Information"), "InfoQuestion"]
            [I18n.screw("Schreibaufgabe"), "WritingQuestion"]
            [I18n.screw("Textlücken"), "GapsQuestion"]
            [I18n.screw("Multiple Choice"), "ChoiceQuestion"]
            [I18n.screw("Zuordnung"), "AssignmentQuestion"]
            [I18n.screw("Sprachproduktion"), "RecordingQuestion"]
          ].sort((a, b) => a[0].localeCompare(b))
          value: type
          onChange: setType
  , [questions, props.onQuestionsUpdate, history]

  ReactDom.ul
    id: "exercise-navigation"
    className: classNames("nav", "nav-pills", "nav-stacked")
    questions.map (question, i) =>
      React.createElement Question1,
        key: question.id
        id: question.id
        index: i
        type: question.type
        questionGroupId: props.questionGroupId
        active: question.id is props.questionId
        onMove: handleMoveQuestion
        onMoveCanceled: handleMoveQuestionCanceled
        onMoveCompleted: handleMoveQuestionCompleted
    ReactDom.li null,
      ReactDom.a
        onClick: showAddQuestionModal
        "+ Frage"

renderQuestionGroup = (props, updateQuestionGroup, updateQuestions) =>
  handleDeleteQuestionGroup = =>
    props.onQuestionGroupsUpdate(props.questionGroups.filter((item) => item.id isnt props.questionGroup.id))

  updateName = (value) =>
    updateQuestionGroup(name: value)

  ReactDom.div
    className: classNames("row")
    ReactDom.div
      className: classNames("col-xs-2")
      React.createElement Sidebar,
        questionGroupId: props.questionGroup.id
        questions: props.questionGroup.questions
        onQuestionsUpdate: updateQuestions
    ReactDom.div
      className: classNames("col-xs-14")
      ReactDom.div
        className: "form-horizontal"
        ReactDom.br null
        React.createElement StringInput,
          name: I18n.screw("Name")
          value: props.questionGroup.name
          onChange: updateName
      React.createElement Link1,
        className: "btn btn-danger"
        confirm: true
        onClick: handleDeleteQuestionGroup
        I18n.screw("Lernschritt löschen")

renderQuestion = (props, history, question, updateQuestions, handleCopyHelpText) =>
  handleQuestionUpdate = (question, data) =>
    question = Object.assign({}, question, data)
    questions = props.questionGroup.questions.map((item) => if item.id is question.id then question else item)
    updateQuestions(questions)

  handleCopyHelpTextToQuestionGroup = (data) =>
    questions = props.questionGroup.questions.map((item) => Object.assign({}, item, help: question.help))
    updateQuestions(questions)

  handleDeleteQuestion = (question) =>
    updateQuestions(props.questionGroup.questions.filter((item) => item.id isnt question.id))

  handleCloneQuestion = (originalQuestion) =>
    question = Object.assign({}, originalQuestion, id: _.uniqueId())
    updateQuestions(props.questionGroup.questions.concat([question]))
    history.push("/questionGroups/#{props.questionGroup.id}/questions/#{question.id}")

  ReactDom.div
    className: classNames("row")
    ReactDom.div
      className: classNames("col-xs-2")
      React.createElement Sidebar,
        questionId: question.id
        questionGroupId: props.questionGroup.id
        questions: props.questionGroup.questions
        onQuestionsUpdate: updateQuestions
    ReactDom.div
      className: classNames("col-xs-14")
      if question?
        React.createElement React.Fragment, null,
          React.createElement Question,
            Object.assign({}, question, key: question.id, onUpdate: handleQuestionUpdate.bind(@, question), onCopyHelpText: handleCopyHelpText.bind(@, question), onCopyHelpTextToQuestionGroup: handleCopyHelpTextToQuestionGroup.bind(@, question))
          React.createElement Link1,
            className: "btn btn-danger"
            confirm: true
            onClick: handleDeleteQuestion.bind(@, question)
            I18n.screw("Frage löschen")
          " "
          React.createElement Link1,
            className: "btn btn-default"
            confirm: true
            onClick: handleCloneQuestion.bind(@, question)
            I18n.screw("Frage duplizieren")

QuestionGroup = (props) =>
  history = useHistory()

  updateQuestionGroup = (data) =>
    questionGroup = Object.assign({}, props.questionGroup, data)
    props.onQuestionGroupsUpdate(props.questionGroups.map((item) => if item.id is questionGroup.id then questionGroup else item))

  updateQuestions = (questions) =>
    updateQuestionGroup(questions: questions)

  handleCopyHelpText = (question) =>
    newQuestionGroups = props.questionGroups.map (questionGroup) =>
      Object.assign {}, questionGroup,
        questions: questionGroup.questions.map((item) => Object.assign({}, item, help: question.help))
    props.onQuestionGroupsUpdate(newQuestionGroups)

  React.createElement Switch, null,
    React.createElement Route,
      path: props.match.path
      exact: true
      ({match}) =>
        renderQuestionGroup(props, updateQuestionGroup, updateQuestions)
    React.createElement Route,
      path: "#{props.match.path}/questions/:questionId"
      ({match}) =>
        {questionId} = match.params
        question = props.questionGroup.questions.find((item) => item.id is questionId)
        if question?
          renderQuestion(props, history, question, updateQuestions, handleCopyHelpText)
        else
          history.replace("/questionGroups/#{props.questionGroup.id}")

export default QuestionGroup
