import React from "react"
import classNames from "classnames"
import {div, label, span} from "react-dom-factories"

class Component extends React.Component
  renderError: =>
    return unless @props.errors?.length > 0# && @props.changed
    span
      key: "errors"
      className: "help-block"
      @props.errors.join(", ")

  render: =>
    div
      className: classNames("form-group","has-error": @props.errors?.length > 0)
      label
        className: "col-sm-2 control-label"
        htmlFor: @props.htmlFor
        [
          @props.name
          "*" if @props.required
        ]
      div
        className: "col-sm-10"
        [
          @props.children
          if @props.description && @props.type isnt "confirmation"
            span
              key: "description"
              className: "hint-block"
              dangerouslySetInnerHTML:
                __html: @props.description
          @renderError()
        ]

export default Component
