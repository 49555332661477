import React from "react"
import ReactDom from "react-dom-factories"

import VirtualSelect from "react-virtualized-select"

import Panel from "exercises/editor/panel/index"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @state = {}

  handleAnswerChange: (i, event) =>
    answers = @props.answers.slice()
    answers[i] = event.target.value
    @props.onChange({answers})

  handleExpectedAnswersCountChange: (event) =>
    @props.onChange(expectedAnswersCount: parseInt(event.target.value))

  handleExactOrderingChange: (event) =>
    @props.onChange(exactOrdering: !@props.exactOrdering)

  render: =>
    ReactDom.div null,
      React.createElement Panel,
        header:
          if @props.expectedAnswersCount > 1
            React.createElement React.Fragment, null,
              I18n.screw("%{count} Antworten sind verlangt.", count: @props.expectedAnswersCount)
              " "
              if @props.exactOrdering
                I18n.screw("Die Reihenfolge der Antworten wird geprüft.")
              else
                I18n.screw("Die Reihenfolge der Antworten wird nicht geprüft.")
          else
            I18n.screw("1 Antwort ist verlangt.")
        React.createElement React.Fragment, null,
          [0..9].map (i) =>
            ReactDom.input
              key: i
              className: "form-control"
              value: @props.answers[i] ? ""
              onChange: @handleAnswerChange.bind(@, i)
      ReactDom.br null

      React.createElement Panel,
        header: I18n.screw("Einstellungen")
        React.createElement React.Fragment, null,
          ReactDom.select
            className: "form-control"
            value: @props.expectedAnswersCount
            onChange: @handleExpectedAnswersCountChange
            ReactDom.option
              value: 1
              I18n.screw("1 Antwort verlangt")
            [2..6].map (i) =>
              ReactDom.option
                key: i
                value: i
                I18n.screw("%{count} Antworten verlangt", count: i)
          ReactDom.div
            className: "checkbox"
            ReactDom.label null,
              ReactDom.input
                type: "checkbox"
                checked: @props.exactOrdering
                onChange: @handleExactOrderingChange
              I18n.screw("Gleiche Reihenfolge der Antworten verlangen")

export default Component
