import React, {useState, useEffect} from "react"
import ReactDom from "react-dom-factories"

import {Link} from "react-router-dom"

import classNames from "classnames"

import QuestionGroupTab from "./question_group_tab"

TopNavigation = (props) =>
  [questionGroups, setQuestionGroups] = useState(props.questionGroups)

  useEffect =>
    setQuestionGroups(props.questionGroups)
  , [props.questionGroups]

  handleMoveGroup = (dragIndex, hoverIndex) =>
    dragGroup = questionGroups[dragIndex]
    questionGroups = questionGroups.slice()
    questionGroups.splice(dragIndex, 1)
    questionGroups.splice(hoverIndex, 0, dragGroup)
    setQuestionGroups(questionGroups)

  handleMoveGroupCanceled = =>
    setQuestionGroups(props.questionGroups)

  handleMoveGroupCompleted = =>
    props.onQuestionGroupsUpdate(questionGroups)

  handleMoveQuestion = (questionId, dstQuestionGroupId) =>
    questionGroups = questionGroups.slice()

    for group in questionGroups
      i = group.questions.findIndex((question) => question.id is questionId)
      continue if i < 0

      if group.id is dstQuestionGroupId
        props.onQuestionGroupsUpdate(questionGroups)
        return

      group.questions = group.questions.slice()
      question = group.questions.splice(i, 1)[0]
      break

    dstQuestionGroup = questionGroups.find((group) => group.id is dstQuestionGroupId)
    dstQuestionGroup.questions = dstQuestionGroup.questions.concat(question)

    props.onQuestionGroupsUpdate(questionGroups)

  handleAddQuestionGroup = =>
    props.onQuestionGroupsUpdate(questionGroups.concat(id: _.uniqueId(), questions: []))

  ReactDom.div null,
    ReactDom.ul
      className: classNames("nav", "nav-tabs")
      ReactDom.li
        className: classNames(active: !props.questionGroup?)
        React.createElement Link,
          to: "/"
          I18n.screw("Informationen")
      questionGroups.map (group, i) =>
        React.createElement QuestionGroupTab,
          key: group.id
          id: group.id
          index: i
          title: group.name
          active: group is props.questionGroup
          onMove: handleMoveGroup
          onMoveCanceled: handleMoveGroupCanceled
          onMoveCompleted: handleMoveGroupCompleted
          onMoveQuestion: handleMoveQuestion
      ReactDom.li null,
        ReactDom.a
          onClick: handleAddQuestionGroup
          "+ Lernschritt"
    props.children

export default TopNavigation
