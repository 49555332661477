React = require("react")
classNames = require("classnames")
_ = require("lodash")

# Always use the polyfill because it always records in WAV format. If using the
# native implementation the format depends on the browser... :(
MediaRecorder = require("audio-recorder-polyfill")

{div, a, audio, p, button, strong, br} = require("react-dom-factories")

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      state: "inited"

  componentDidMount: =>
    @audioEl?.addEventListener("ended", @onPlaybackEnded, false)
    @originalMedia = $(".right").find(".media").find("audio, video")[0]

  onStartRecording: =>
    navigator.mediaDevices.getUserMedia(audio: true).then (stream) =>
      @recorder = new MediaRecorder(stream)
      @recorder.addEventListener "dataavailable", (e) =>
        @recording = e.data
        @uploadRecording()
      @startOriginalMedia()
      @recorder.start()
      @setState(state: "recording")

  onStopRecording: =>
    @stopOriginalMedia()
    @recorder.stop()
    @recorder.stream.getTracks()[0].stop()

  onStartPlayback: =>
    url = URL.createObjectURL(@recording)
    @audioEl.src = url
    @audioEl.controls = true
    @audioEl.play()
    @startOriginalMedia()
    @setState(state: "playback")

  onPlaybackEnded: =>
    @stopOriginalMedia()
    @audioEl.controls = false
    @setState(state: "recordingReady")

  uploadRecording: =>
    form = new FormData()
    form.append("file", @recording)
    upload = $.ajax
      type: "POST"
      url: @props.uploadUrl
      data: form,
      processData: false
      contentType: false
    upload.done (data) =>
      window.hasRecording = true
      @setState(state: "recordingReady")
    @setState(state: "uploadingRecording")

  startOriginalMedia: =>
    return unless @originalMedia?
    if @originalMedia.readyState > 0
      @originalMedia.pause()
      @originalMedia.currentTime = 0
    @originalMedia.play()

  stopOriginalMedia: =>
    return unless @originalMedia?
    @originalMedia.pause()

  hasBrowserSupport: =>
    navigator.mediaDevices?.getUserMedia?

  renderTool: =>
    div null,
      switch @state.state
        when "inited"
          a
            className: "btn btn-success"
            onClick: @onStartRecording
            I18n.screw("Aufnahme starten")
        when "recording"
          a
            className: "btn btn-danger"
            onClick: @onStopRecording
            I18n.screw("Aufnahme beenden")
        when "recordingReady"
          div null,
            div null,
              a
                className: "btn btn-default"
                onClick: @onStartRecording
                I18n.screw("Neue Aufnahme")
              " "
              a
                className: "btn btn-primary"
                onClick: @onStartPlayback
                I18n.screw("Aufnahme abspielen")
            div null,
              br null
              strong null,
                I18n.screw("Wenn die Aufnahme ok ist -> Speichern durch Klick auf <Lösen>.")
        when "uploadingRecording"
          p null,
            I18n.screw("Aufnahme wird übermittelt...")
      audio
        ref: (el) => @audioEl = el
        controls: false
  render: =>
    div null,
      if @hasBrowserSupport()
        @renderTool()
      else
        div
          className: "alert alert-danger"
          I18n.screw("Dein Browser unterstützt leider keine Tonaufnahme. Bitte aktualisiere auf eine neuere Version.")

module.exports = Component
