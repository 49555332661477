import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Panel from "exercises/editor/panel/index"
import Choice from "./choice"

import {ResizableBox} from "react-resizable"
import resizableStyles from "react-resizable/css/styles.css"

import Events from "src/events"

class AssignmentQuestion extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      bbox: null

  componentDidMount: =>
    @setBbox()
    window.addEventListener("scroll", @setBbox)

  componentWillUnmount: =>
    window.removeEventListener("scroll", @setBbox)

  componentWillUpdate: (nextProps, nextState) =>
    return if nextState.bbox is @state.bbox
    Events.trigger("targets-container-bbox:update", nextState.bbox)

  setBbox: =>
    bbox = @containerEl.getBoundingClientRect()
    bbox.height = bbox.width * @props.targetsBboxAspectRatio
    @setState({bbox})
    Events.trigger("targets-container-bbox:update", bbox)

  handleTargetChange: (data) =>
    @props.onChange(targets: @props.targets.map((item, i) => if data.id is i then Object.assign({}, item, data) else item))

  handleDraggableChange: (data) =>
    @props.onChange(draggables: @props.draggables.map((item, i) => if data.id is i then Object.assign({}, item, data) else item))

  handleExactPositionChange: (data) =>
    @props.onChange(exactPosition: !@props.exactPosition)

  handleTargetsCountChange: (event) =>
    count = event.target.value
    defaultData =
      contentBox:
        x: 0
        y: 0
        width: 25
        aspectRatio: 1
    @props.onChange(targets: [1..count].map((i) => @props.targets[i - 1] ? defaultData))

  handleDraggablesCountChange: (event) =>
    count = event.target.value
    defaultData =
      contentBox:
        width: 25
        aspectRatio: 0.5
    @props.onChange(draggables: [1..count].map((i) => @props.draggables[i - 1] ? defaultData))

  handleTargetsKindChange: (event) =>
    targetsKind = event.target.value
    targets = @props.targets.map((item) => Object.assign({}, item, contentData: null))
    @props.onChange({targetsKind, targets})

  handleDraggablesKindChange: (event) =>
    draggablesKind = event.target.value
    draggables = @props.draggables.map((item) => Object.assign({}, item, contentData: null))
    @props.onChange({draggablesKind, draggables})

  calculateBbox: (row, col, rows, cols) =>
    width = 1 / cols * 100
    aspectRatio = (@state.bbox.height / rows) / (@state.bbox.width * width / 100)
    x = width * (col - 1)
    y = (1 / rows) * (row - 1) * 100
    {width, aspectRatio, x, y}

  handleArrangeTargetsVertical: =>
    targets = @props.targets.map (item, i) =>
      contentBox = Object.assign({}, item.contentBox, @calculateBbox(i + 1, 1, @props.targets.length, 1))
      Object.assign({}, item, {contentBox})
    @props.onChange({targets})

  handleArrangeTargetsHorizontal: =>
    targets = @props.targets.map (item, i) =>
      contentBox = Object.assign({}, item.contentBox, @calculateBbox(1, i + 1, 1, @props.targets.length))
      Object.assign({}, item, {contentBox})
    @props.onChange({targets})

  handleArrangeTargetsMixed: =>
    rows = {1: 1, 2: 1, 3: 1, 4: 2, 5: 2, 6: 2}[@props.targets.length]
    cols = {1: 1, 2: 2, 3: 3, 4: 2, 5: 3, 6: 3}[@props.targets.length]
    targets = @props.targets.map (item, i) =>
      row = Math.ceil((i + 1) / cols)
      col = (i % cols) + 1
      contentBox = Object.assign({}, item.contentBox, @calculateBbox(row, col, rows, cols))
      Object.assign({}, item, {contentBox})
    @props.onChange({targets})

  handleDraggablesSetSameWidth: (mathFunc) =>
    widths = @props.draggables.map((item, i) => item.contentBox.width)
    desiredWidth = Math[mathFunc](widths...)
    draggables = @props.draggables.map (item, i) =>
      aspectRatio = item.contentBox.aspectRatio * item.contentBox.width / desiredWidth
      contentBox = Object.assign({}, item.contentBox, width: desiredWidth, aspectRatio: aspectRatio)
      Object.assign({}, item, {contentBox})
    @props.onChange({draggables})

  handleDraggablesSetSameHeight: (mathFunc) =>
    heights = @props.draggables.map((item, i) => item.contentBox.width * item.contentBox.aspectRatio)
    desiredHeight = Math[mathFunc](heights...)
    draggables = @props.draggables.map (item, i) =>
      aspectRatio = desiredHeight / item.contentBox.width
      contentBox = Object.assign({}, item.contentBox, aspectRatio: aspectRatio)
      Object.assign({}, item, {contentBox})
    @props.onChange({draggables})

  handleTargetsBboxResize: (event, {size})=>
    targetsBboxAspectRatio = size.height / size.width
    factor = @props.targetsBboxAspectRatio / targetsBboxAspectRatio
    targets = @props.targets.map (item, i) =>
      contentBox = Object.assign({}, item.contentBox, y: item.contentBox.y * factor)
      Object.assign({}, item, {contentBox})
    draggables = @props.draggables.map (item, i) =>
      contentBox = Object.assign({}, item.contentBox, y: if item.contentBox.y? then item.contentBox.y * factor)
      Object.assign({}, item, {contentBox})
    @props.onChange({targetsBboxAspectRatio, targets, draggables})
    setTimeout(@setBbox, 0)

  transformedBox: (box) =>
    x = if box.x? then box.x / 100 * @state.bbox.width
    y = if box.y? then box.y / 100 * @state.bbox.height
    width = box.width / 100 * @state.bbox.width
    height = width * box.aspectRatio
    {x, y, width, height}

  render: =>
    ReactDom.div null,
      React.createElement Panel,
        header: I18n.screw("Zieh / Ziehen Sie die Objekte auf der rechten Seite an die richtige Stelle.")
        ReactDom.div
          ref: (el) => @containerEl = el
          if @state.bbox?
            React.createElement ResizableBox,
              axis: "y"
              handle:
                ReactDom.div
                  className: cx("react-resizable-handle", "react-resizable-handle-s", "targets-container-resize-handle")
              width: @state.bbox.width
              height: @state.bbox.height
              minConstraints: [20, 20]
              onResizeStop: @handleTargetsBboxResize
              ReactDom.div
                style:
                  position: "relative"
                  height: "100%"
                @props.targets.map (target, i) =>
                  React.createElement Choice,
                    key: i
                    id: i
                    title: "Z"
                    kind: @props.targetsKind
                    box: @transformedBox(target.contentBox)
                    originalBox: target.contentBox
                    targetsContainerBbox: @state.bbox
                    contentData: target.contentData
                    onChange: @handleTargetChange
      ReactDom.br null

      React.createElement Panel,
        header: I18n.screw("Einstellungen")
        React.createElement React.Fragment, null,
          ReactDom.div
            className: "form-group"
            ReactDom.div
              className: "form-inline"
              ReactDom.label
                className: "control-label"
                I18n.screw("Zielfelder")
              " "
              ReactDom.select
                className: "form-control"
                value: @props.targets.length
                onChange: @handleTargetsCountChange
                [1..6].map (i) =>
                  ReactDom.option
                    key: i
                    value: i
                    i
              " "
              ReactDom.select
                className: "form-control"
                value: @props.targetsKind
                onChange: @handleTargetsKindChange
                ReactDom.option
                  value: "text"
                  I18n.screw("Texte")
                ReactDom.option
                  value: "media"
                  I18n.screw("Medien")
              " "
              ReactDom.button
                className: "btn btn-default"
                onClick: @handleArrangeTargetsVertical
                "V-Layout"
              " "
              ReactDom.button
                className: "btn btn-default"
                onClick: @handleArrangeTargetsHorizontal
                "H-Layout"
              " "
              ReactDom.button
                className: "btn btn-default"
                onClick: @handleArrangeTargetsMixed
                "M-Layout"
          ReactDom.div
            className: "form-group"
            ReactDom.div
              className: "form-inline"
              ReactDom.label
                className: "control-label"
                I18n.screw("Lösungen")
              " "
              ReactDom.select
                className: "form-control"
                value: @props.draggables.length
                onChange: @handleDraggablesCountChange
                [1..20].map (i) =>
                  ReactDom.option
                    key: i
                    value: i
                    i
              " "
              ReactDom.select
                className: "form-control"
                value: @props.draggablesKind
                onChange: @handleDraggablesKindChange
                ReactDom.option
                  value: "text"
                  I18n.screw("Texte")
                ReactDom.option
                  value: "media"
                  I18n.screw("Medien")
              " "
              ReactDom.span
                className: "btn-group"
                ReactDom.span
                  className: classNames("btn", "btn-default")
                  "Gleiche Breite"
                ReactDom.span
                  className: classNames("btn", "btn-default")
                  onClick: => @handleDraggablesSetSameWidth("min")
                  ReactDom.i
                    className: classNames("fa", "fa-compress")
                ReactDom.span
                  className: classNames("btn", "btn-default")
                  onClick: => @handleDraggablesSetSameWidth("max")
                  ReactDom.i
                    className: classNames("fa", "fa-expand")
              " "
              ReactDom.span
                className: "btn-group"
                ReactDom.span
                  className: classNames("btn", "btn-default")
                  "Gleiche Höhe"
                ReactDom.span
                  className: classNames("btn", "btn-default")
                  onClick: => @handleDraggablesSetSameHeight("min")
                  ReactDom.i
                    className: classNames("fa", "fa-compress")
                ReactDom.span
                  className: classNames("btn", "btn-default")
                  onClick: => @handleDraggablesSetSameHeight("max")
                  ReactDom.i
                    className: classNames("fa", "fa-expand")

          ReactDom.div
            className: "form-group"
            ReactDom.div
              className: "checkbox"
              ReactDom.label null,
                ReactDom.input
                  type: "checkbox"
                  checked: @props.exactPosition
                  onChange: @handleExactPositionChange
                I18n.screw("Exakte Zielorte")

export default AssignmentQuestion
