import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Answer from "./answer"

class AssignmentQuestion extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      resetCounter: 0
      bbox: null
      draggables: props.draggables.slice()
    window.updateAssignmentQuestion = ({draggables}) =>
      @setState((state) => resetCounter: state.resetCounter + 1)
      @setState({draggables})

  componentDidMount: =>
    @setBbox()
    @setBboxTimeout = setInterval(@setBbox, 250)
    window.addEventListener("scroll", @setBbox)

  componentWillUnmount: =>
    clearTimeout(@setBboxTimeout)
    window.removeEventListener("scroll", @setBbox)

  setBbox: =>
    bbox = @containerEl.getBoundingClientRect()
    bbox.height = bbox.width * @props.targetsBboxAspectRatio
    @setState({bbox})

  transformedBox: (box) =>
    x = if box.x? then box.x / 100 * window.bbox.width + (window.bbox.x - @state.bbox.x)
    y = if box.y? then box.y / 100 * window.bbox.height + (window.bbox.y - @state.bbox.y)
    width = box.width / 100 * window.bbox.width
    height = width * box.aspectRatio
    {x, y, width, height}

  render: =>
    ReactDom.div
      key: @state.resetCounter
      className: cx("sidebar")
      ref: (el) => @containerEl = el
      if @state.bbox?
        @state.draggables.map (draggable) =>
          React.createElement Answer,
            key: draggable.id
            id: draggable.id
            kind: @props.draggablesKind
            box: @transformedBox(draggable.contentBox)
            contentData: draggable.contentData

export default AssignmentQuestion
