import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

import Target from "./target"

class AssignmentQuestion extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      bbox: null

  componentDidMount: =>
    @setBbox()
    @setBboxTimeout = setInterval(@setBbox, 250)
    window.addEventListener("scroll", @setBbox)

  componentWillUnmount: =>
    clearTimeout(@setBboxTimeout)
    window.removeEventListener("scroll", @setBbox)

  setBbox: =>
    bbox = @containerEl.getBoundingClientRect()
    window.bbox = bbox
    bbox.height = bbox.width * @props.targetsBboxAspectRatio
    @setState({bbox})

  transformedBox: (box) =>
    x = if box.x? then box.x / 100 * @state.bbox.width
    y = if box.y? then box.y / 100 * @state.bbox.height
    width = box.width / 100 * @state.bbox.width
    height = width * box.aspectRatio
    {x, y, width, height}

  render: =>
    ReactDom.div
      ref: (el) => @containerEl = el
      style:
        position: "relative"
        height: if @state.bbox? then "#{@state.bbox.height}px" else "auto"
      if @state.bbox?
        @props.targets.map (target, i) =>
          React.createElement Target,
            key: i
            kind: @props.targetsKind
            box: @transformedBox(target.contentBox)
            contentData: target.contentData

export default AssignmentQuestion
