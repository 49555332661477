import React from "react"
import ReactDom from "react-dom-factories"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"
cx = classNames.bind(styles)

class Tristate extends React.Component
  handleClick: =>
    @props.onChange({false: true, true: null, null: false}[@props.value])

  render: =>
    ReactDom.div
      className: cx("container", @props.className)
      onClick: @handleClick
      switch @props.value
        when null
          ReactDom.i
            className: cx("checkbox", "ambiguous")
        when true
          ReactDom.i
            className: cx("checkbox", "fa", "fa-check")

export default Tristate
