import React from "react"
import ReactDom from "react-dom-factories"
import Wrapper from "./wrapper"
import _ from "lodash"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @id = _.uniqueId("input-")
    @state =
      changed: false

  onChange: (event) =>
    @setState(changed: true)
    @props.onChange(event.target.value)

  getCollection: =>
    isCustomInput = @props.value? && !@props.collection.find(([_, value]) => value is @props.value)
    @props.collection.concat(if isCustomInput then [[@props.value, @props.value]] else [])

  render: =>
    options = @getCollection().map(([label, value]) -> {label, value})
    React.createElement Wrapper,
      Object.assign({}, @props, htmlFor: @id, changed: @state.changed)
      for option in options
        ReactDom.div
          key: option.value
          className: "radio"
          ReactDom.label null,
            ReactDom.input
              type: "radio"
              value: option.value
              checked: @props.value is option.value
              onChange: @onChange
            option.label

export default Component
