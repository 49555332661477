import React from "react"
import classNames from "classnames"
import {div, textarea} from "react-dom-factories"
import Wrapper from "./wrapper"
import _ from "lodash"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @id = _.uniqueId("input-")
    @state =
      changed: false

  onChange: (event) =>
    @setState(changed: true)
    @props.onChange(event.target.value)

  render: =>
    React.createElement Wrapper,
      Object.assign({}, @props, htmlFor: @id, changed: @state.changed)
      textarea
        id: @id
        key: "input"
        type: "text"
        className: "form-control"
        disabled: @props.readonly
        value: @props.value ? ""
        placeholder: @props.placeholder
        rows: @props.rows
        onChange: @onChange
        onBlur: @props.onUpdate

export default Component
