import React from "react"
import {div, input} from "react-dom-factories"
import Wrapper from "./wrapper"
import _ from "lodash"

import Select, {Creatable} from "react-select"

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @id = _.uniqueId("input-")
    @state =
      changed: false

  onChange: (selected) =>
    @setState(changed: true)
    @props.onChange(selected?.value)

  getCollection: =>
    isCustomInput = @props.value? && !@props.collection.find(([_, value]) => value is @props.value)
    @props.collection.concat(if isCustomInput then [[@props.value, @props.value]] else [])

  shouldKeyDownEventCreateNewOption: (e) =>
    e.keyCode in [9, 13] # tab, enter

  promptTextCreator: (label) =>
    label

  render: =>
    options = @getCollection().map(([label, value]) -> {label, value})
    React.createElement Wrapper,
      Object.assign({}, @props, htmlFor: @id, changed: @state.changed)
      if @props.allowCustomInput
        React.createElement Creatable,
          id: @id
          key: "input"
          isClearable: true
          className: "react-select"
          classNamePrefix: "react_select"
          isDisabled: @props.readonly
          value: options.find(({value}) => value is @props.value)
          options: options
          placeholder: @props.placeholder ? ""
          onChange: @onChange
          shouldKeyDownEventCreateNewOption: @shouldKeyDownEventCreateNewOption
          promptTextCreator: @props.promptTextCreator ? @promptTextCreator
      else
        React.createElement Select,
          id: @id
          key: "input"
          className: "react-select"
          classNamePrefix: "react_select"
          isDisabled: @props.readonly
          value: options.find(({value}) => value is @props.value)
          options: options
          placeholder: @props.placeholder ? ""
          onChange: @onChange

export default Component
