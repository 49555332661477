React = require("react")
classNames = require("classnames")
_ = require("lodash")
Events = require("src/events")
Table = require("./table")

{div, input} = require("react-dom-factories")

class Component extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      query: ""
      items: null
      matchIndex: null
    @isVisible = false
    @deboucedhighlightMatch = _.debounce(@highlightMatch, 250)

  componentDidMount: =>
    Events.on "dictionary:show", (query) =>
      @show(query)

    Events.on "dictionary:toggle", (query) =>
      return @isVisible = false if @isVisible
      @show(query)

  show: (query) =>
      @isVisible = true
      @loadDictionary()
      @setState(query: query) if query?

  loadDictionary: =>
    callback = =>
      @deboucedhighlightMatch()
      @input.focus()

    return callback() if @state.items?
    $.getJSON @props.dictionarySource, (result) =>
      @setState(items: result, callback)

  handleQueryChange: (e) =>
    @setState(query: e.target.value, => @deboucedhighlightMatch())

  highlightMatch: =>
    index = null
    if @state.query isnt ""
      query = @state.query.toLowerCase()
      index = @state.items.findIndex((item) => item.word.toLowerCase().indexOf(query.toLowerCase()) is 0)
      index = null if index < 0
    @setState(matchIndex: index, => @scrollToMatch())

  scrollToMatch: =>
    return unless @state.matchIndex?
    container = $(@scroller)
    worker = =>
      if container.is(":visible")
        row = container.find("tr:nth-child(#{@state.matchIndex+1})")
        offset = $(row).offset().top - container.offset().top + container.scrollTop()
        container.animate(scrollTop: offset, 250)
      else
        setTimeout(worker, 100)
    worker()

  render: =>
    div null,
      if @state.items?
        div null,
          input
            ref: (el) => @input = el
            type: "text"
            className: "no-continue"
            value: @state.query
            placeholder: I18n.screw("Suche")
            onChange: @handleQueryChange
          div
            ref: (el) => @scroller = el
            className: "scroller"
            React.createElement Table,
              items: @state.items
              highlightedRowIndex: @state.matchIndex
      else
        I18n.screw("Das Wörterbuch wird geladen...")

module.exports = Component
